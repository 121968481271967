import React, { useState } from "react";
import styled from "styled-components";
import { convertYMDToMDY } from "../utils/DateConversion";
import { FairSelectorModal } from "./FairSelector/FairSelectorModal";
import { trackChangeFair, trackCoaChangeFair, trackCoaConfirmationChangeFair } from "../DumbleData";

interface Props {
	enableSwitch: boolean;
	fairInfoData: any;
	organizationData: any;
	language: string;
	pageType: string;
}

export const FairInfoBar = ({ enableSwitch, fairInfoData, organizationData, language, pageType }: Props) => {
	const [fairSelectorModalOpen, setFairSelectorModalOpen] = useState(false);

	const openFairSelectorModal = () => {
		if (pageType === "coa-confirmed") {
			trackCoaConfirmationChangeFair();
		} else if (pageType === "coa") {
			trackCoaChangeFair();
		} else if (pageType === "fair-details") {
			trackChangeFair();
		}
		setFairSelectorModalOpen(true);
		window.parent.postMessage("openModal", window.location.origin);
		document.documentElement.classList.add("bookfairs-modal--open");
	};

	const closeFairSelectorModal = () => {
		setFairSelectorModalOpen(false);
		window.parent.postMessage("closeModal", window.location.origin);
		document.documentElement.classList.remove("bookfairs-modal--open");
	};

	return (
		<>
			<FairInfoBarDiv>
				{fairInfoData && (
					<>
						<FairName>{organizationData.name.split(/\s+/).map((word: any) => { 
								return word[0].toUpperCase() + word.substring(1).toLowerCase(); 
							}).join(" ")}
						</FairName>
						<FairDate addExtraPadding={!enableSwitch}>
							{language === "en" ? "Fair Dates: " : "Dates du Festival : "}
							{language === "en" ? 
								`${convertYMDToMDY(fairInfoData.start)} — ${convertYMDToMDY(fairInfoData.end)}` 
								: `${fairInfoData.start} — ${fairInfoData.end}`}
						</FairDate>
						{enableSwitch && (
							<StyledTextLink
								onClick={() => {
									openFairSelectorModal();
								}}
							>
								{language === "en" ? "Select Different Fair" : "Sélectionner un Festival différent"}
							</StyledTextLink>
						)}
					</>
				)}
			</FairInfoBarDiv>
			{fairSelectorModalOpen && (
				<FairSelectorModal
					closeFairSelectorModal={closeFairSelectorModal}
					language={language}
				/>
			)}
		</>
	);
};

const FairInfoBarDiv = styled.div`
	grid-column: 1 / 24;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-bottom: 1px solid #d7d7d7;
`;

const FairName = styled.div`
	color: var(--text-primary, #333);
	text-align: center;
	font-size: 32px;
	font-style: normal;
	font-weight: 500;
	line-height: 35px;
	margin-top: 24px;

	@media (max-width: 375px) {
		font-size: 21px;
		line-height: 24px; /* 114.286% */
	}
`;

interface FairDateProps {
	addExtraPadding: boolean;
}
const FairDate = styled.div<FairDateProps>`
	margin-top: 8px;
	color: var(--text-secondary, #666);
	text-align: center;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 15px;
	margin-bottom: ${(props) => (props.addExtraPadding ? "24px" : "0px")};
`;

const StyledTextLink = styled.button`
	background: none;
	border: none;
	text-decoration: none;
	font-style: normal;
	color: var(--text-default-clifford-red, #e81111);
	text-align: center;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 15px;
	width: 150px;
	margin: 8px auto 24px auto;
	&:hover {
		color: #000000;
		text-decoration: none;
		cursor: pointer;
	}
	&:active {
		color: #000000;
	}
	&:focus,
	:focus-visible {
		color: #000000;
		outline: none;
		outline-offset: 0;
		border-radius: 0;
	}
`;

export default FairInfoBar;

import styled from "styled-components";

export const Grid = styled.div`
	display: grid;
	margin: 0 auto 24px;
	grid-template-columns: repeat(23, 1fr);
	width: -webkit-fill-available;
	width: -moz-available;
	max-width: 1240px;
	/* IE friendly.....*/
	display: -ms-grid;
	-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr
		1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	@media (max-width: 1599px) {
		margin: 0px 180px 24px;
	}
	@media (max-width: 1279px) {
		margin: 0px 140px 24px;
	}
	@media (max-width: 959px) {
		margin: 0px 40px 24px;
	}
	@media (max-width: 719px) {
		max-width: 480px;
		margin: 0 auto 24px;
	}
	@media (max-width: 519px) {
		margin: 0px 20px 24px;
	}
`;

export const FinancialFormGrid = styled.div`
	@media (max-width: 719px) {
		max-width: 500px;
		margin: 0 auto 24px;
	}
	@media (max-width: 519px) {
		margin: 0px 20px 24px;
	}
`;

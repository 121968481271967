import { combineStringsToSingleDate } from "./DateConversion";
import { addDays } from "date-fns";

const generateCalendarFile = (
	eventName: string,
	eventDescription: string | undefined,
	scheduleDate: string,
	startTime: string | undefined,
	endTime: string | undefined,
	openOrCloseEvent: boolean
) => {
	const eventStartDate: Date = combineStringsToSingleDate(
		scheduleDate,
		startTime
	);
	const eventEndDate: Date = combineStringsToSingleDate(
		scheduleDate,
		endTime
	);

	// format dates to ics time specification
	const dTStart =
		eventStartDate
			.toISOString()
			.split("-")
			.join("")
			.split(":")
			.join("")
			.split(".")[0] + "Z";
	const dTEnd =
		eventEndDate
			.toISOString()
			.split("-")
			.join("")
			.split(":")
			.join("")
			.split(".")[0] + "Z";

	let event = "";

	// generate timestamp for event creation
	const dTStamp =
		new Date()
			.toISOString()
			.split("-")
			.join("")
			.split(":")
			.join("")
			.split(".")[0] + "Z";

	const uuid = Math.floor(Math.random() * 100000000000000);

	if (openOrCloseEvent) {
		// if first or last day of bookfair, mark as full day event, description to match event name
		const endOfDay = addDays(eventStartDate, 1);
		const allDayDtEnd =
			endOfDay
				.toISOString()
				.split("-")
				.join("")
				.split(":")
				.join("")
				.split(".")[0] + "Z";

		event = `BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:Scholastic.com//EN\nCALSCALE:GREGORIAN\nMETHOD:PUBLISH\nBEGIN:VEVENT\nDTSTART:${dTStart}\nDTEND:${allDayDtEnd}\nDTSTAMP:${dTStamp}\nDESCRIPTION:${eventName}\nSUMMARY:${eventName}\nUID:${uuid}\nEND:VEVENT\nEND:VCALENDAR`;
	} else {
		event = `BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:Scholastic.com//EN\nCALSCALE:GREGORIAN\nMETHOD:PUBLISH\nBEGIN:VEVENT\nDTSTART:${dTStart}\nDTEND:${dTEnd}\nDTSTAMP:${dTStamp}\nDESCRIPTION:${eventDescription}\nSUMMARY:${eventName}\nUID:${uuid}\nEND:VEVENT\nEND:VCALENDAR`;
	}

	// format filename, change spaces to underlines
	const formattedFilename = eventName.split(" ").join("_");

	const element = document.createElement("a");
	element.setAttribute(
		"href",
		"data:text/calendar;charset=utf-8," + encodeURIComponent(event)
	);
	element.setAttribute("download", formattedFilename);
	element.style.display = "none";
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
};

export { generateCalendarFile };

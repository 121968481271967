import { useState } from "react";
import styled from "styled-components";
import FairDetailsCard from "./FairDetailsCard";
import moment from "moment";
import { convertStringToDate } from "../../utils/DateConversion";
import { WhiteButton } from "../Button";
import { DateChangeModal } from "../ConfirmYourFair/DateChange/DateChangeModal";

type Props = {
	startDate: string;
	endDate: string;
	setupDate: string;
	language: string;
	setDateChangeSubmit: any;
};

const FairDatesCard = ({ startDate, endDate, setupDate, language, setDateChangeSubmit }: Props) => {
	const bookfairsModalOpen = "bookfairs-modal--open";
	const [dateChangeModalOpen, setDateChangeModalOpen] =
		useState<boolean>(false);
	const englishDateFormat = "MM/DD/YY";
	const frenchDateFormat = "YYYY-MM-DD";

	const openDateChangeModal = () => {
		// trackCoaRequestDateChange();
		setDateChangeModalOpen(true);
		document.documentElement.classList.add(bookfairsModalOpen);
	};
	const closeDateChangeModal = () => {
		setDateChangeModalOpen(false);
		document.documentElement.classList.remove(bookfairsModalOpen);
	};

	const suppressDateChangeRequest = () => {
		const start = moment(startDate);
		const requestWindow = start.subtract(27, 'days');
		const now = moment(); 
		return requestWindow <= now;
	};
	
	if ( language === "en" ) {
		return (
				<FairDetailsCard title={"Fair Dates"}>
						<DateRow>
							<Body>Set-Up Date: <span>{moment(setupDate).format(englishDateFormat).toString()}</span></Body>
							<Body>Fair Start Date: <span>{moment(startDate).format(englishDateFormat).toString()}</span></Body>
							<Body>Fair End Date: <span>{moment(endDate).format(englishDateFormat).toString()}</span></Body>
						</DateRow>
						{ !suppressDateChangeRequest() && (
							<StyledWhiteButton 
								className={"date-change"}
								handleClick={openDateChangeModal} 
								whiteBackground={true}>
								{language === "en" ? "Request Date Change" : "Demander un changement de date"}
							</StyledWhiteButton>
							)}
							{dateChangeModalOpen && (
								<DateChangeModal
									language={language}
									closeModal={closeDateChangeModal}
									setDateChangeSubmitOpen={setDateChangeSubmit}
									currStartDate={convertStringToDate(startDate)}
									currEndDate={convertStringToDate(endDate)}
								/>
							)}
				</FairDetailsCard>	
		);
	} else {
		return (
			<FairDetailsCard title={"Dates du Festival"}>
				<Body>Date d’installation : <span>{moment(setupDate).format(frenchDateFormat).toString()}</span></Body>
				<Body>Date de début du Festival : <span>{moment(startDate).format(frenchDateFormat).toString()}</span></Body>
				<Body>Date de fin du Festival : <span>{moment(endDate).format(frenchDateFormat).toString()}</span></Body>
			</FairDetailsCard>
		)
	}
};

const Body = styled.div`
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	overflow: hidden;
	& span {
		font-weight: 400;
		display: block;
	}
`;

const DateRow = styled.div`
	display: flex;
	gap: 32px;
	padding-bottom: 27px;

	@media (max-width: 519px) {
		flex-direction: column;
	}
`;

const StyledWhiteButton = styled(WhiteButton)`
	padding: 9px 20px;
`;

export default FairDatesCard;
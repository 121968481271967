const validateEmail = (email: string) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
};

const validatePhoneNumber = (phoneNumber: string) => {
	let cleanNumber = phoneNumber;

	//remove country code to allow to validate length of phone number
	if (phoneNumber.substring(0, 2) === "+1") {
		cleanNumber = phoneNumber.replace("+1", "");
		cleanNumber = cleanNumber.replace(" ", "");
	}
	//check length of number without extreneous characters
	if (cleanNumber.replaceAll(/[^0-9]/g, "").length !== 10) {
		return false;
	}

	//validates format of number
	return String(cleanNumber)
		.toLowerCase()
		.match(/^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im);
};

const validateURL = (url: string) => {
	const urlPattern = /^[0-9A-Za-z\-.]+$/;
	return urlPattern.test(url);
};

export { validateEmail, validatePhoneNumber, validateURL };

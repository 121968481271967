import React, { useState, useMemo, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/misc.css";
import styled from "styled-components";
import TextInput from "../TextInput";
import { RedButton } from "../Button";
import TextLink from "../TextLink";
import MovedIcon from "../../images/Moved.svg";
import Prompt from "../HomepageBuilder/Prompt";
import RequestPasswordModal from "./RequestPasswordModal";
import { trackLoginPageSubmit, trackLoginPageRequestPassword, updateUserLoginStatus } from "../../DumbleData";
import { handleGenericErrors } from "../../utils/ErrorUtility";

interface Props {
	language: string;
	setLanguage?: any;
	setIsLoggedIn: any;
}

const Login = ({ language, setLanguage, setIsLoggedIn }: Props) => {
	const { lang } = useParams();
	const navigate = useNavigate();
	const [emailAddress, setEmailAddress] = useState("");
	const [errorEmailAddress, setErrorEmailAddress] = useState<string>();
	const [password, setPassword] = useState("");
	const [errorPassword] = useState<string>();
	const [apiError, setApiError] = useState<string>();
	const [saveChangesModalOpen, setSaveChangesModalOpen] = useState(false);
	const [allChangesSaved, setAllChangesSaved] = useState(true);

	const redirect = new URLSearchParams(document.location.search)
		if ( redirect.get("url") !== null ) {
			navigate('/login', { replace: true });
			window.location.href = redirect.get("url") || "";
		}

	const validateField = useCallback((field: string) => {
		if (field === "email") {
			if (
				/^[A-Z0-9._'%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(emailAddress)
			) {
				setErrorEmailAddress(undefined);
			} else {
				setErrorEmailAddress(
					`${
						language === "en"
							? "Please enter a valid email address."
							: "Veuillez inscrire une adresse courriel valide."
					}`
				);
			}
		}
	}, [emailAddress, language]);

	useEffect(() => {
		if (lang && (lang === "en" || lang === "fr") && lang !== language) {
			window.sessionStorage.setItem("bf_lang", lang);
			setLanguage(lang);
			navigate("/login");
		} else {
			navigate("/login", { replace: true });
			setSaveChangesModalOpen(false);
			if ( errorEmailAddress ) {
				validateField("email");
			} else if (apiError) {
				setApiError(language === "en" ? 'Invalid email or password, please try again' : 'Adresse courriel ou mot de passe non valide. Essayez à nouveau.');
			}

		}
	}, [lang, language, navigate, setLanguage, setSaveChangesModalOpen, apiError, errorEmailAddress, validateField]);

	const checkLogin = () => {
    	setApiError(undefined);
		axios({
		method: 'post',
		url: `${process.env.REACT_APP_ENV_BASE_URL}/api/user/login`,
		data: {
			email: emailAddress,
			password: password
		}
		})
		.then(function(response) {
			if ( response?.data?.message === "Successful" ) {
				window.sessionStorage.setItem('bf_profile', "show");
				setIsLoggedIn(true);
				updateUserLoginStatus(true);
				axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current?mode=SELECT`, {
					withCredentials: true
				})
				.then(function(res){
					if ( res.data?.fair?.coaAccepted === false ) {
						navigate('/confirm-fair');
					} else {
						navigate('/fair-details');
					}
					})
				.catch(function(error){
					console.log(error);
				});
			} else {
				handleGenericErrors(response);
			}
		})
		.catch(function(error) {
			window.sessionStorage.removeItem('bf_profile');
			setIsLoggedIn(false);
			setApiError(language === "en" ? 'Invalid email or password, please try again' : 'Adresse courriel ou mot de passe non valide. Essayez à nouveau.');
		});
	};

	const isValid = useMemo(
		() =>
			/^[A-Z0-9._'%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(emailAddress) &&
			password.trim().length > 0,
		[emailAddress, password]
	);

	useEffect(() => {
	  axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current?mode=SELECT`, {
		withCredentials: true
	  })
	  .then(function(res){
		if ( res.status === 200 ) {
			window.sessionStorage.setItem('bf_profile', "show");
			setIsLoggedIn(true);
			if ( res.data?.fair?.coaAccepted === false ) {
				navigate('/confirm-fair');
			} else {
				navigate('/fair-details');
			}
		}
		})
	  .catch(function(error){
		console.log(error);
	  });

	});
	

	return (
		<>
			<StyledMovedBanner>
					<img src={MovedIcon} alt="We've moved" />
					<StyledMovedText>
						<StyledMovedHeading>
							{language === "en" ? "We've moved! Welcome to the NEW Book Fairs Host Hub!" : "De nouveaux outils pour vous! Bienvenue dans le NOUVEAU portail du Festival du Livre!"}
						</StyledMovedHeading>
						<StyledMovedBodyText>
							{language === "en" ? "We've simplified the Toolkit to make it easier than ever to plan your Fair. Login using your Toolkit credentials to begin exploring." : "Nous vous proposons un nouveau portail qui rendra la planification de votre Festival plus facile que jamais. Découvrez les nouveaux outils dès maintenant!"}
						</StyledMovedBodyText>
					</StyledMovedText>
				</StyledMovedBanner>
			<StyledLoginPage>
				<Heading>{language === "en" ? "Sign In" : "Connexion"}</Heading>
				<StyledBodyText>
					{language === "en"
						? "Use the password assigned to you or request one below."
						: "Utilisez le mot de passe qui vous a été assigné ou demandez-en-un ci-dessous."}
				</StyledBodyText>
				<Wrapper>
          {apiError && <StyledErrorText>{apiError}</StyledErrorText>}
					<FlexRow>
						<InlineElement>
							<TextInput
								id="email"
								label={
									language === "en"
										? "Email Address"
										: "Adresse courriel"
								}
								value={emailAddress}
								required={true}
								onChange={(v: string) => {
									setEmailAddress(v);
									validateField("email");
								}}
								error={errorEmailAddress}
							/>
						</InlineElement>
					</FlexRow>
					<FlexRow>
						<InlineElement>
							<TextInput
								id="password"
								type="password"
								label={
									language === "en"
										? "Password"
										: "Mot de passe"
								}
								value={password}
								onChange={setPassword}
								required={true}
								error={errorPassword}
							/>
						</InlineElement>
					</FlexRow>
					<FlexRow $buttons="true">
						<InlineElement>
							<StyledTextLink
								handleClick={() => {
									setAllChangesSaved(false);
									setSaveChangesModalOpen(true);
									trackLoginPageRequestPassword();
								}}
							>
								{language === "en"
									? "Request Password"
									: "Demander un mot de passe"}
							</StyledTextLink>
						</InlineElement>
						<InlineElement>
							<StyledRedButton
								id="sign-in-button"
								disabled={!isValid}
								handleClick={() => {
									trackLoginPageSubmit();
									checkLogin();
								}}
							>
								{language === "en" ? "Sign In" : "Connexion"}
							</StyledRedButton>
						</InlineElement>
					</FlexRow>
					<FlexRow>
						<InlineElement>
							{language === "en" ? (
								<StyledDisclaimer>
									If you are a new Book Fair Host, or have
									forgotten your password, please select
									"Request password".
									<br />
									<br />
									Are you a volunteer? Contact your Book Fair
									Host for access!
								</StyledDisclaimer>
							) : (
								<StyledDisclaimer>
									Si vous êtes un·e nouveau·elle responsable du Festival du Livre, ou si vous avez oublié votre mot de passe, veuillez sélectionner « Demander un mot de passe ».
									<br />
									<br />
									Si vous êtes un·e bénévole, communiquez avec votre responsable pour obtenir l’accès.
								</StyledDisclaimer>
							)}
						</InlineElement>
					</FlexRow>
					<Row>
						<div>
							{language === "en" ? (
								<a
									className="footer_privacyLink"
									href="https://www.scholastic.ca/aboutscholastic/privacy.php"
									target="_blank"
									rel="noopener noreferrer"
								>
									Privacy Policy
								</a>
							) : (
								<a
									className="footer_privacyLink"
									href="https://www.scholastic.ca/editions/lentreprise/confidentialite.php"
									target="_blank"
									rel="noopener noreferrer"
								>
									Politique de confidentialité
								</a>
							)}
							{language === "en" ? (
								<a
									className="footer_termsOfUse"
									href="https://www.scholastic.ca/aboutscholastic/webterms.php"
									target="_blank"
									rel="noopener noreferrer"
								>
									Terms of Use
								</a>
							) : (
								<a
									className="footer_termsOfUse"
									href="https://www.scholastic.ca/editions/lentreprise/modalites-dutilisation.php"
									target="_blank"
									rel="noopener noreferrer"
								>
									Conditions d’utilisation
								</a>
							)}
						</div>

						{language === "en" ? (
							<StyledDisclaimer>
								™ <sup>&reg;</sup> &amp; &copy; {new Date().getFullYear()}{" "}
								Scholastic Inc. All Rights Reserved.
							</StyledDisclaimer>
						) : (
							<StyledDisclaimer>
								™ <sup>&reg;</sup> et © Scholastic Inc.,{" "}
								{new Date().getFullYear()}. Tous droits
								réservés.
							</StyledDisclaimer>
						)}
					</Row>
				</Wrapper>
			</StyledLoginPage>
			<Prompt
				message={() => {
					setSaveChangesModalOpen(true);
				}}
				when={!allChangesSaved}
			/>
			<RequestPasswordModal
				modalIsOpen={saveChangesModalOpen}
				language={language}
				handleModal={() => {
					setAllChangesSaved(true);
					setSaveChangesModalOpen(!saveChangesModalOpen);
				}}
			/>
		</>
	);
};

/*
  Page Specific Styles
*/
const StyledLoginPage = styled.div`
	font-family: museo-sans;
	max-width: 440px;
	margin: 47px auto 154px;
	@media (max-width: 1279px) {
		margin: 48px auto;
		max-width: 418px;
	}
	@media (max-width: 719px) {
		margin: 32px auto;
    padding: 0 20px;
    max-width: 440px;
	}
`;

const StyledMovedBanner = styled.div`
	padding: 10px 40px;
	display: flex;
	gap: 14px;
	background: #FFCDD7;
	@media (max-width: 719px) {
		padding: 10px 24px;
	}
`;

const StyledMovedText = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

const StyledMovedHeading = styled.p`
	font-weight: 700;
`;

const StyledMovedBodyText = styled.p`
`;

const Heading = styled.h3`
	font-size: 28px;
	font-weight: 400;
	line-height: 32px;
	text-align: center;
	color: #3859a2;
	margin: 0 0 8px 0;
	@media (max-width: 719px) {
		font-size: 24px;
		line-height: 28px;
	}
`;

const StyledBodyText = styled.p`
	font-size: 17px;
	line-height: 25px;
	text-align: center;
	color: #333333;
	font-weight: 300;
	margin-bottom: 20px;
`;

const StyledErrorText = styled.p`
	font-size: 14px;
	line-height: 19px;
	text-align: center;
	color: rgb(232, 17, 17);
	font-weight: 300;
`;


const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 24px;
`;

const Row = styled.div`
	text-align: center;

	& > div {
		margin-bottom: 16px;
	}
`;

const FlexRow = styled.div<{ $buttons?: string }>`
	margin-top: 0;
	margin-bottom: 0;
	display: inline-flex;
	width: 100%;
	gap: 40px;
	@media (max-width: 1279px) {
		gap: 14px;
	}
	@media (max-width: 719px) {
		display: block;
		text-align: ${(props) =>
			props.$buttons === "true" ? "center" : "left"};
	}
`;

const InlineElement = styled.div`
	flex: 1 auto;
	width: calc(50% - 20px);
	@media (max-width: 719px) {
		width: 100%;
	}
`;

const StyledRedButton = styled(RedButton)`
	width: 150px;
	text-transform: capitalize;
	float: right;
	@media (max-width: 719px) {
		float: none;
	}
`;

const StyledTextLink = styled(TextLink)`
	margin-top: 10px;
	@media (max-width: 719px) {
		margin-bottom: 16px;
	}
  @media (min-width: 720px) {
    text-align: left;
    padding: 0;
  }
`;

const StyledDisclaimer = styled.p`
	font-size: 12px;
	line-height: 17px;
	text-align: center;
	color: #333333;
	font-weight: 400;
	margin-bottom: 60px;
	@media (max-width: 719px) {
		margin-bottom: 24px;
	}
	@media (max-width: 374px) {
	}
`;

export default Login;

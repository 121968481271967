import styled from "styled-components";

type Props = {
	children: React.ReactNode;
	openOrCloseEvent: string | undefined;
};

const Tooltip = ({ children, openOrCloseEvent }: Props) => {
	return (
		<StyledTooltip className={`tooltip ${openOrCloseEvent}`}>
			{children}
		</StyledTooltip>
	);
};

const StyledTooltip = styled.div`
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.3s;
	position: absolute;
	background: #333333;
	color: #ffffff;
	border-radius: 4px;
	padding: 5px;
	text-align: center;
	font-size: 10px;
	line-height: 11px;
	font-family: "museo-sans";
	weight: 400;
	box-shadow: 0 0 6px #d7d7d7;
	top: -21px;
	left: -22px;
	@media (max-width: 670px) {
		display: none;
	}
`;

export default Tooltip;

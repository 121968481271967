import React, { useEffect, useState } from "react";
import moment from "moment";
import { IconButtonWhite, RedButton, WhiteButton } from "../Button";
import Email from "../../images/Email.svg";
import TextInput from "../TextInput";
import Checkbox from "../Checkbox";
import styled from "styled-components";
import {
	ChairpersonData,
	FairInfoData,
	OrganizationData,
} from "./ConfirmYourFair";
import { convertYMDToMDY, convertStringToDate} from "../../utils/DateConversion";
import axios from "axios";
import { PrintableComponent } from "../PrintableComponent";
import { EmailAgreementModal } from "./EmailModals/EmailAgreementModal";
import { EmailSuccessModal } from "./EmailModals/EmailSuccessModal";
import { EmailFailureModal } from "./EmailModals/EmailFailureModal";
import { DateChangeModal } from "./DateChange/DateChangeModal";
import { DateChangeSubmitPage } from "./DateChange/DateChangeSubmit";
import COAConfirmed from "./COAConfirmed";
import {
	handleGenericErrors,
	handleGenericSuccess,
} from "../../utils/ErrorUtility";
import EarningChart from "./EarningChart";
import { trackCoaEmail, trackCoaAccept, trackCoaRequestDateChange, trackCoaFlyerCountFinalAmount } from "../../DumbleData";

interface Props {
	language: string;
	fairInfoData: FairInfoData;
	chairpersonData: ChairpersonData;
	organizationData: OrganizationData;
	coaConfirmedOpen: boolean;
	setCoaConfirmedOpen: any;
}

export const ConfirmYourFairContent = ({
	language,
	fairInfoData,
	chairpersonData,
	organizationData,
	coaConfirmedOpen,
	setCoaConfirmedOpen
}: Props) => {
	const [fifthAndBelow, setFifthAndBelow] = useState(fairInfoData.fliers.fifthAndBelow);
	const [sixthAndAbove, setSixthAndAbove] = useState(fairInfoData.fliers.sixthAndAbove);
	const [fifthAndBelowError, setFifthAndBelowError] = useState<string>("");
	const [sixthAndAboveError, setSixthAndAboveError] = useState<string>("");
	const [changeFlyerClicked, setChangeFlyerClicked] = useState<boolean>(false);

	const [acceptChecked, setAcceptChecked] = useState<boolean>(false);
	const [emailAgreementModalOpen, setEmailAgreementModalOpen] =
		useState<boolean>(false);
	const [emailSuccessModalOpen, setEmailSuccessModalOpen] =
		useState<boolean>(false);
	const [emailFailureModalOpen, setEmailFailureModalOpen] =
		useState<boolean>(false);
	const [dateChangeModalOpen, setDateChangeModalOpen] =
		useState<boolean>(false);
	const [dateChangeSubmitPageOpen, setDateChangeSubmitPageOpen] =
		useState<boolean>(false);
	const handleAcceptCheckbox = () => {
		acceptChecked ? setAcceptChecked(false) : setAcceptChecked(true);
	};

	const planningKitShipped = fairInfoData.planningKitShipped;

	const handleAcceptClicked = () => {
			acceptRequest();
	};
	const acceptRequest = () => {
			axios.put(`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current/coa/confirmation`,
					{ language: language === "en" ? "english" : "french" },
					{ withCredentials: true, }
				)
				.then((coaResponse) => {
					if (coaResponse.status === 200) {
						if (!coaConfirmedOpen) {
							setCoaConfirmedOpen(true);
						}
						trackCoaAccept();
						trackCoaFlyerCountFinalAmount(fifthAndBelow, sixthAndAbove);
					} else {						
						handleGenericSuccess(coaResponse);
					}
				})
				.catch(function (error) {
					handleGenericErrors(error);
				});
		
	};

	const bookfairsModalOpen = "bookfairs-modal--open";

	const openEmailAgreementModal = () => {
		trackCoaEmail();
		setEmailAgreementModalOpen(true);
		document.documentElement.classList.add(bookfairsModalOpen);
	};
	const closeEmailAgreementModal = () => {
		setEmailAgreementModalOpen(false);
		document.documentElement.classList.remove(bookfairsModalOpen);
	};

	const openEmailSuccessModal = () => {
		setEmailSuccessModalOpen(true);
		document.documentElement.classList.add(bookfairsModalOpen);
	};
	const closeEmailSuccessModal = () => {
		setEmailSuccessModalOpen(false);
		document.documentElement.classList.remove(bookfairsModalOpen);
	};

	const openEmailFailureModal = () => {
		setEmailFailureModalOpen(true);
		document.documentElement.classList.add(bookfairsModalOpen);
	};
	const closeEmailFailureModal = () => {
		setEmailFailureModalOpen(false);
		document.documentElement.classList.remove(bookfairsModalOpen);
	};

	const openDateChangeModal = () => {
		trackCoaRequestDateChange();
		setDateChangeModalOpen(true);
		document.documentElement.classList.add(bookfairsModalOpen);
	};
	const closeDateChangeModal = () => {
		setDateChangeModalOpen(false);
		document.documentElement.classList.remove(bookfairsModalOpen);
	};

	const suppressDateChangeRequest = () => {
		const start = moment(fairInfoData.start);
		const requestWindow = start.subtract(27, 'days');
		const now = moment(); 
		return requestWindow <= now;
	};

	// validate flyer count if change flyer is clicked
	useEffect(() => {
		if (changeFlyerClicked) {
			if ((!fifthAndBelow || fifthAndBelow < 1) && (!sixthAndAbove || sixthAndAbove < 1)) {
				language === "en" ? setFifthAndBelowError("Total number of flyers can't be less than 1.") : setFifthAndBelowError("Le nombre total de dépliants ne peut être inférieur à 1.");
				language === "en" ? setSixthAndAboveError("Total number of flyers can't be less than 1.") : setSixthAndAboveError("Le nombre total de dépliants ne peut être inférieur à 1.");
			} else {
				setFifthAndBelowError("");
				setSixthAndAboveError("");
			}
		}
		else {
			setFifthAndBelowError("");
			setSixthAndAboveError("");
		}
	}, [changeFlyerClicked,
		fifthAndBelow,
		sixthAndAbove,
		language
	]);

	const handleFlyerChange = (e: any, inputName: string) => {
		if (e.length === 0) {
			inputName === "fifthAndBelow" ? setFifthAndBelow(0) : setSixthAndAbove(0);
		} else if (e.length < 4) {
			inputName === "fifthAndBelow" ? setFifthAndBelow(e.replace(/^0+/, "").replace(/[^0-9]/g,"")) : setSixthAndAbove(e.replace(/^0+/, "").replace(/[^0-9]/g,""));
		}
	};

	const handleFlyerChangeSaved = (fifthAndBelow: any, sixthAndAbove: any) => {
		axios.put(`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current/fliers`,
				{
					fifthAndBelow: parseInt(fifthAndBelow),
					sixthAndAbove: parseInt(sixthAndAbove),
				},
				{ withCredentials: true, }
			)
			.catch(function (error) {
				handleGenericErrors(error);
			});
	};

	useEffect(() => {
		if(dateChangeSubmitPageOpen || coaConfirmedOpen) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}
	}, [dateChangeSubmitPageOpen, coaConfirmedOpen]);

	return (
		<>
			{emailAgreementModalOpen && (
				<EmailAgreementModal
					language={language}
					closeModal={closeEmailAgreementModal}
					openEmailSuccessModal={openEmailSuccessModal}
					openEmailFailureModal={openEmailFailureModal}
				/>
			)}
			{emailSuccessModalOpen && (
				<EmailSuccessModal
					language={language}
					closeModal={closeEmailSuccessModal} />
			)}
			{emailFailureModalOpen && (
				<EmailFailureModal
					language={language}
					openEmailAgreementModal={openEmailAgreementModal}
					closeModal={closeEmailFailureModal}
				/>
			)}
			{dateChangeModalOpen && (
				<DateChangeModal
					language={language}
					closeModal={closeDateChangeModal}
					setDateChangeSubmitOpen={setDateChangeSubmitPageOpen}
					currStartDate={convertStringToDate(fairInfoData.start)}
					currEndDate={convertStringToDate(fairInfoData.end)}
				/>
			)}
			{(dateChangeSubmitPageOpen || fairInfoData.requestDateChangePending) && (
				<DateChangeSubmitPage
					language={language}
				/>
			)}
			{coaConfirmedOpen && (
				<COAConfirmed
					language={language}
				/>
			)}
			<Heading>{language === "en" ? "Confirm Your Book Fair" : "Confirmer votre Festival"}</Heading>
			<Container>
				<PrintEmailContainer>
					<PrintEmailInnerContainer>
						<BodyText>{language === "en" ? 
							"If someone else needs to confirm these details, click below for a version to print or email." :
							"Si une autre personne doit confirmer les détails de votre Festival, inscrivez son adresse courriel ci-dessous et une copie lui sera envoyée en votre nom."}
						</BodyText>
						<ButtonContainer>
							<StyledPrintableComponent
								buttonText={language === "en" ? "Print" : "Imprimer"}
								redTextButton={false}
								fairInfoData={fairInfoData}
								chairpersonData={chairpersonData}
								organizationData={organizationData}
								language={language}
							/>
							<StyledIconButtonWhite
								iconAlt="Email"
								iconSrc={Email}
								iconWidth={14}
								handleClick={openEmailAgreementModal}>
								{language === "en" ? "Email" : "Envoyer par courriel"}
							</StyledIconButtonWhite>
						</ButtonContainer>
					</PrintEmailInnerContainer>
				</PrintEmailContainer>
				<SchoolDetailsContainer>
					<SchoolTitle>{fairInfoData.name}</SchoolTitle>
					<SchoolDetails>
						<div>
							{organizationData.address.line1}{" "}
							{organizationData.address.city},{" "}
							{organizationData.address.province}{" "}
							{organizationData.address.postalCode}
						</div>
						<div>{language === "en" ? "Account #:" : "Numéro de compte : "}{organizationData.bookfairAccountId}</div>
						<div>{language === "en" ? "Fair ID:" : "Numéro du Festival : "}{fairInfoData.id}</div>
						<div>{language === "en" ?  "Set-Up Date:" : "Date d’installation : "}{convertYMDToMDY(fairInfoData.setup)}</div>
						<div>{language === "en" ? "Start Date:" : "Date de début du Festival : "}{convertYMDToMDY(fairInfoData.start)}</div>
						<div>{language === "en" ? "End Date:" : "Date de fin du Festival : "}{convertYMDToMDY(fairInfoData.end)}</div>
						<DateWarning>
							{language === "en" ? "*Fair delivery and pickup dates to be scheduled." : "*Dates de livraison et de ramassage du Festival à programmer."}
						</DateWarning>
					</SchoolDetails>
					{ !suppressDateChangeRequest() && (<StyledWhiteButton 
						className={"date-change"}
						handleClick={openDateChangeModal} 
						whiteBackground={true}>
						{language === "en" ? "Request Date Change" : "Demander un changement de date"}
					</StyledWhiteButton>)}
				</SchoolDetailsContainer>
				<HorizontalBreak />
				<FlyersContainer>
					<Subheading>{language === "en" ? "Number of Flyers Required" : "Nombre de dépliants requis"}</Subheading>
					<FlexRow className={"flyers"}>
						<StyledTextInput 
							className={"flyers"}
							value={fifthAndBelow.toString()}
							label={language === "en" ? "JK-Grade 5" : "Préscolaire à 5ᵉ année"}
							error={fifthAndBelowError !== "" ? fifthAndBelowError : undefined}
							type={"text"}
							onChange={(e: any) => {handleFlyerChange(e, "fifthAndBelow")}} 
							disabled={!changeFlyerClicked}
							/>
						<StyledTextInput 
							className={"flyers"}
							value={sixthAndAbove.toString()}
							label={language === "en" ? "Grades 6-8" : "6ᵉ à 8ᵉ année"}
							error={sixthAndAboveError !== "" ? sixthAndAboveError : undefined}
							type={"text"}
							onChange={(e: any) => {handleFlyerChange(e, "sixthAndAbove")}} 
							disabled={!changeFlyerClicked}
							/>
					</FlexRow>
					{  }
					{(!planningKitShipped && changeFlyerClicked) &&
						<StyledRedButton 
							handleClick={(e: any) => {
								handleFlyerChangeSaved(fifthAndBelow, sixthAndAbove);
								setChangeFlyerClicked(!changeFlyerClicked)}
							} 
							whiteBackground={true}
							disabled={!!fifthAndBelowError || !!sixthAndAboveError}
							>
							{language === "en" ? "Save Flyer Quantity" : "Enregistrer la quantité de dépliants"}
						</StyledRedButton>}
					{(!planningKitShipped && !changeFlyerClicked) &&
						<StyledWhiteButton 
							handleClick={(e: any) => {setChangeFlyerClicked(!changeFlyerClicked)}} 
							whiteBackground={true}>
							{language === "en" ? "Edit Flyer Quantity" : "Modifier la quantité de dépliants"}
						</StyledWhiteButton>
					}
				</FlyersContainer>
				<HorizontalBreak />
				<PersonalInfoContainer>
					<Subheading>{language === "en" ? "Your Information" : "Vos coordonnées"}</Subheading>
					<PILowerContainer>
						<PILeftContainer>
							<BodyText className={"thin"}>{language === "en" ? "Contact:" : "Nom :"}</BodyText>
							<BodyText className={"thin"}>{language === "en" ? "Phone:" : "Numéro de téléphone :"}</BodyText>
							<BodyText className={"thin"}>{language === "en" ? "Email:" : "Courriel :"} </BodyText>
						</PILeftContainer>
						<PIRightContainer>
							<ChairInfoText>
								{chairpersonData.firstName}{" "}{chairpersonData.lastName}	
							</ChairInfoText>
							<ChairInfoText>
								{chairpersonData.phoneNumber.slice(0,3)}-{chairpersonData.phoneNumber.slice(3,6)}-{chairpersonData.phoneNumber.slice(6)}
							</ChairInfoText>
							<ChairInfoText>
								{chairpersonData.email}
							</ChairInfoText>
						</PIRightContainer>
					</PILowerContainer>
				</PersonalInfoContainer>
				<EarningChart language={language}/>
				<LegalInfoContainer>
					<LegalInfoText>
						{language === "en" ? <b>Scholastic Dollars Earning Structure</b> : <b>Structure des gains en dollars Scholastic</b>}
					</LegalInfoText>
					<LegalInfoText>
						{language === "en" ?
						`Your school may opt to take the appropriate percentage of the event's 
						net sales in products from the Fair, Scholastic Dollars, cash, or a 
						combination of these options. See the chart above for all sales levels 
						and earning structure details. Your Rewards Worksheet will provide 
						calculations of each option to facilitate the selection of product 
						rewards, Scholastic Dollars, or cash. ` :
						`Votre école peut choisir de recevoir le pourcentage approprié des ventes 
						nettes de l’événement sous forme de produits du Festival, de dollars 
						Scholastic, d’argent comptant ou d’une combinaison de ces options. 
						Consultez le tableau ci-dessus pour connaître tous les paliers de vente 
						et les détails de la structure des gains. Votre formulaire de récompenses 
						vous fournira les calculs de chaque option afin de faciliter la sélection 
						des récompenses en produits, en dollars Scholastic ou en argent. `}
						{language === "en" ? <b>Please note:</b> : <b>Remarque :</b>}
						{language === "en" ? ` the cash redemption option is not available in the province of Quebec.` :
							` L’option de paiement en argent n'est pas disponible dans la province de Québec.`}
					</LegalInfoText>
					<LegalInfoText>
						{language === "en" ? <b>Scholastic Book Fair Delivery Fee and Late Cancellation Policy</b> :
						<b>Frais de livraison et politique d’annulation tardive pour le Festival du Livre Scholastic</b>}
					</LegalInfoText>
					<LegalInfoText>
						{language === "en" ?
						`A standard Delivery Fee of $25.00CAD will be applied to each scheduled In-School Book Fair. This fee will be decremented from the earned Scholastic Dollars upon completion of the worksheet.` 
						: `Des frais de livraison standard de 25 $ canadiens seront appliqués à chaque Festival du Livre programmé. Ces frais seront déduits des dollars Scholastic gagnés une fois le formulaire de récompenses rempli.`}
						<br/><br/>
						{language === "en" ? `It is critical that all Scholastic Book Fairs be confirmed a minimum of 3 weeks prior to the Fair Set-Up date, as this allows us time for production and final delivery preparations. Please note that a Late Cancellation Fee of $100.00CAD will be applied to those In-School Fairs who cancel a confirmed Book Fair within 5 business days of the Set-Up Date. This fee will be decremented from the Scholastic Dollars balance on the account within 48 hours of cancellation.` 
						: `Il est essentiel que tous les Festivals du Livre soient confirmés au moins trois semaines avant la date de montage prévue, car cela nous laisse le temps de procéder aux derniers préparatifs en ce qui a trait à la production et à la livraison. Veuillez noter que des frais d’annulation tardive de 100 $ seront appliqués aux Festivals qui annulent un événement confirmé dans les cinq jours ouvrables précédant la date de mise en place. Ces frais seront déduits du solde des dollars Scholastic figurant au compte dans les 48 heures suivant l’annulation.`}
						<br/><br/>
						{language === "en" ? <i>Please Note: If the Scholastic Dollars balance cannot accommodate the Delivery Fee or Late Cancellation Fee, an invoice will be generated and supplied via email to the Book Fair Host.</i> :
							<i>Remarque : Si le solde des dollars Scholastic ne peut pas couvrir les frais de livraison ou les frais d’annulation tardive, une facture sera envoyée par courriel à la personne responsable du Festival du Livre.</i>}
					</LegalInfoText>
				</LegalInfoContainer>
				<CheckboxContainer>
					<Checkbox
						checked={acceptChecked}
						onChange={() => handleAcceptCheckbox()}
						label={language === "en" ?
							"By clicking on ACCEPT, I confirm that I have authority to agree to the above terms and conditions on behalf of the above named school." :
							"En cliquant sur ACCEPTER, je confirme que j’ai l’autorité nécessaire pour accepter les conditions générales ci-dessus au nom de l’école susmentionnée."}
						alignItemsStart={true}
					/>
				</CheckboxContainer>
				<AcceptButtonContainer>
					<StyledRedButton
						disabled={!acceptChecked}
						handleClick={handleAcceptClicked}
					>
						{language === "en" ? "Accept" : "Accepter"}
					</StyledRedButton>
				</AcceptButtonContainer>
			</Container>
		</>
	);
};

const StyledTextInput = styled(TextInput)`
    width: calc(50% - 16px);
	min-width: 280px;

	// @media (max-width: 640px) {
	// 	width: 100%;
	// }
`;

const FlexColumn = styled.div`
	display: flex;
	flex-direction: column;
`;

const FlexRow = styled.div`
	display: flex;

	&.flyers {
		gap: 32px;
		flex-wrap: wrap;
	}
`;

// Container Styling

const AcceptButtonContainer = styled(FlexRow)`
	gap: 24px;
	flex-wrap: wrap;
	margin-bottom: 12px;
	@media (max-width: 500px) {
		width: 100%;
	}
`;

const ButtonContainer = styled(FlexRow)`
	gap: 21px;
	flex-wrap: wrap;
	@media (max-width: 500px) {
		gap: 14px;
	}
`;

const Container = styled(FlexColumn)`
	align-items: flex-start;
	width: 100%;
	gap: 40px;
	grid-column-start: 1;
	grid-column-end: 24;
`;

const PrintEmailContainer = styled.div`
	background: #fff8f8;
	border: 1px solid #ffb3b3;
	width: 100%;
`;

const PrintEmailInnerContainer = styled(FlexColumn)`
	align-items: flex-start;
	gap: 23px;
	padding: 24px 39px 35px;
	@media (max-width: 500px) {
		padding: 24px 12px;
	}
`;

const LegalInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

const LegalInfoText = styled.p`
	font-size: 17px;
	font-weight: 250;
	line-height: 25px;
`;

const SchoolDetailsContainer = styled.div``;

const FlyersContainer = styled(FlexColumn)`
	gap: 20px;
`;

const PersonalInfoContainer = styled(FlexColumn)`
	gap: 16px;
`;

const PILowerContainer = styled(FlexRow)`
	gap: 23px;
`;

const PILeftContainer = styled(FlexColumn)`
	gap: 15px;
	align-items: flex-end;
	padding-left: 55px;
	@media (max-width: 500px) {
		padding-left: 30px;
	}
`;

const PIRightContainer = styled(PILeftContainer)`
	align-items: flex-start;
	padding: unset;
`;

const CheckboxContainer = styled(FlexRow)`
	max-width: 537px;
	font-size: 13px;
	font-weight: 400;
	line-height: 18px;
`;

// Component Styling

const Heading = styled.div`
	font-weight: 500;
	font-size: 28px;
	line-height: 32px;
	color: #333333;
	grid-column-start: 1;
	grid-column-end: 24;
	margin-top: 48px;
	margin-bottom: 16px;
	@media (max-width: 400px) {
		font-size: 24px;
		line-height: 28px;
	}
`;

const Subheading = styled.div`
	font-style: normal;
	font-weight: 550;
	font-size: 18px;
	line-height: 21px;
`;

const BodyText = styled.div`
	font-family: "museo-sans";
	font-weight: 300;
	font-size: 17px;
	line-height: 25px;
	ul {
		margin-left: 20px;
		list-style-type: disc;
	}

	ul li p {
		margin-left: 0px !important;
	}

	&.thin {
		font-weight: 250;
	}
`;

const ChairInfoText = styled(BodyText)`
	word-break: break-word;
	min-height: 25px;
	font-weight: 250;
`;

const SchoolTitle = styled.div`
	font-weight: 400;
	font-size: 21px;
	line-height: 24px;
`;

const SchoolDetails = styled.div`
	font-weight: 250;
	font-size: 17px;
	line-height: 25px;
	color: #333333;
	margin-top: 8px;
`;

const DateWarning = styled.div`
	padding-top: 25px;
	color: #333;
	font-weight: 500;
	font-size: 13px;
`;

const HorizontalBreak = styled.hr`
	width: 100%;
	height: 1px;
	border: none;
	background: #ebebeb;
	margin: 0;
`;

const StyledWhiteButton = styled(WhiteButton)`
	padding: 11px 24px;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;

	&.date-change {
		margin-top: 16px;
	}
`;

const StyledRedButton = styled(RedButton)`
	min-width: 130px;
	height: 40px;
	border-radius: 100px;
`;

const StyledPrintableComponent = styled(PrintableComponent)`
	width: unset;
`;

const StyledIconButtonWhite = styled(IconButtonWhite)`
	width: unset;
`;
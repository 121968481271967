import React, { useEffect, useState, useCallback, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useOutletContext } from "react-router-dom";
import { addDays, subDays } from "date-fns";
import { Page } from "../Page";
import Heading from "../../Heading";
import PageIcon from "../PageIcon";
import MessageIcon from "../../../images/Message.svg";
import RedArrowRight from "../../../images/RedArrowRight.svg";
import RedArrowUp from "../../../images/RedArrowUp.svg";
import BodyText from "../../BodyText";
import TextInput from "../../TextInput";
import { RedButton } from "../../Button";
import TextLink from "../../TextLink";
import Checkbox from "../../Checkbox";
import LimitedTextArea from "../../LimitedTextArea";
import SelectInput from "../../DropDownSelect";
import { useConfig } from "../../../providers/ConfigContext";
import { DatePickerComponent } from "../../DatePicker";
import { convertStringToDate, convertDateToString } from "../../../utils/DateConversion";
import SaveChangesModal from "../SaveChangesModal";
import Prompt from "../Prompt";
import { trackDiscardChanges, trackSaveAndPublishButton, trackSkipButton, trackInSchoolPaymentOptions, trackCustomizeYourMessageEnglish, trackCustomizeYourMessageFrench, updateUserLoginStatus, updateUserFairId, updateUserAccountId, updatePageType, trackCustomizeYourMessageThankYouEnglish, trackCustomizeYourMessageThankYouFrench } from "../../../DumbleData";
import { handleGenericErrors, handleGenericSuccess } from "../../../utils/ErrorUtility";

interface Props {
	language: string;
	builderNavDisabled: boolean;
	setBuilderNavDisabled: any;
	builderNextLocation: string;
	setBuilderNextLocation: any;
}

export const InformationPage = ({ 
	language, 
	builderNavDisabled, 
	setBuilderNavDisabled, 
	builderNextLocation, 
	setBuilderNextLocation }: Props ) => {
	const [isEnglishThankYouMessage, setIsEnglishThankYouMessage] = useState<boolean>(false);
	const [isFrenchThankYouMessage, setIsFrenchThankYouMessage] = useState<boolean>(false);
	const [errorSchoolName, setErrorSchoolName] = useState<string>();
	const [errorEndDate, setErrorEndDate] = useState<string>();
	const [errorStartDate, setErrorStartDate] = useState<string>();
	const [errorAddress, setErrorAddress] = useState<string>();
	const [errorCity, setErrorCity] = useState<string>();
	const [errorRegion, setErrorRegion] = useState<string>();
	const [errorPostalCode, setErrorPostalCode] = useState<string>();
	const [errorLocation, setErrorLocation] = useState<string>();
	const [saveChangesModalOpen, setSaveChangesModalOpen] = useState(false);
	const { damAssetsOrigin } = useConfig();
	const navigate = useNavigate();
	const getRequestDone = useRef(false);
	const [attemptedToSubmit, setAttemptedToSubmit] = useState<boolean>(false);
	const [allChangesSaved, setAllChangesSaved] = useState(true);
	const [allowNavigation, setAllowNavigation] = useState(false);
	const [savedData, setSavedData] = useState<any>();
	const { after: afterFair } = useOutletContext<any>();
	const informationNextLocation = afterFair ? "/customize/share" : "/customize/schedule";
	setBuilderNextLocation(informationNextLocation);
	const [schoolName, setSchoolName] = useState("");
	const [location, setLocation] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [region, setRegion] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [englishWelcomeMessage, setEnglishWelcomeMessage] = useState("");
	const [frenchWelcomeMessage, setFrenchWelcomeMessage] = useState("");
	const [englishThankYouMessage, setEnglishThankYouMessage] = useState("");
	const [frenchThankYouMessage, setFrenchThankYouMessage] = useState("");
	const [cash, setCash] = useState(false);
	const [checks, setChecks] = useState(false);
	const [creditCard, setCreditCard] = useState(false);

	const [defaultStartDate, setDefaultStartDate] = useState<Date>(new Date());
	const [defaultEndDate, setDefaultEndDate] = useState<Date>(new Date());
	const [startDate, setStartDate] = useState<Date>(new Date());
	const [endDate, setEndDate] = useState<Date>(new Date());

	const [isStartCalendarOpen, setIsStartCalendarOpen] = useState<boolean>(false);
	const [isEndCalendarOpen, setIsEndCalendarOpen] = useState<boolean>(false);
	const [provinces, setProvinces] = useState<any>();

	const openStartCalendar = () => {
		setIsStartCalendarOpen(true);
		setIsEndCalendarOpen(false);
	};
	const openEndCalendar = () => {
		setIsEndCalendarOpen(true);
		setIsStartCalendarOpen(false);
	};
	const resetCalendars = () => {
		setIsStartCalendarOpen(false);
		setIsEndCalendarOpen(false);
	};
	
	// Formats Province Dropwdown Options 
	const formatRegions = (regions: any) => {
		let options: string[] = [];
		regions?.map((value: { name: string; code: string; }, index: number, array: { name: string; code: string; }[]) => (
			options.push(value.name)
		))
		return options;
	}

	// Checks if all required fields are supplied
	const dataValidate = useCallback((language: string): [boolean, string] => {
		let isAllValid = true;
		let firstErrorIndex = -1;
		const scrollArray = ["school-name", "start-date", "end-date", "location", "address", "city", "region", "postal-code", "english-english-welcome", "english-english-thank-you", "english-french-welcome", "english-french-thank-you", "french-french-welcome", "french-french-thank-you", "french-english-welcome", "french-english-thank-you"];

		if (!schoolName) {
			language === "en" ? setErrorSchoolName("Please enter the school name.") : setErrorSchoolName("Ce champ est obligatoire.")
			isAllValid = false;
			if (firstErrorIndex === -1) {
				firstErrorIndex = 0;
			}
		} else {
			setErrorSchoolName(undefined);
		}
		if (!endDate) {
			language === "en" ? setErrorEndDate("Please enter an end date within range.") : setErrorEndDate("Veuillez saisir une date de fin comprise dans les dates de la saison de votre Festival.")
			isAllValid = false;
			if (firstErrorIndex === -1) {
				firstErrorIndex = 2;
			}
		} else {
			setErrorEndDate(undefined);
		}
		if (!startDate) {
			language === "en" ? setErrorStartDate("Please enter a start date within range.") : setErrorStartDate("Veuillez saisir une date de début comprise dans les dates de la saison de votre Festival.")
			isAllValid = false;
			if (firstErrorIndex === -1) {
				firstErrorIndex = 1;
			}
		} else {
			setErrorStartDate(undefined);
		}
		if (startDate && endDate) { 
			if (endDate < startDate) {
				language === "en" ? setErrorEndDate("Please enter an end date that follows the start date.") : setErrorEndDate("La date de fin doit être après la date de début.")
				isAllValid = false;
				if (firstErrorIndex === -1) {
					firstErrorIndex = 2;
				}
			} else {
				setErrorEndDate(undefined);
			}
		}
		if (!address) {
			language === "en" ? setErrorAddress("Please enter a valid address.") : setErrorAddress("Veuillez inscrire une adresse valide.")
			isAllValid = false;
			if (firstErrorIndex === -1) {
				firstErrorIndex = 4;
			}
		} else {
			setErrorAddress(undefined);
		}
		if (location && location.length > 40) {
			language === "en" ? setErrorLocation("Please do not exceed 40 characters.") : setErrorLocation("Veuillez ne pas dépasser 40 caractères.")
			isAllValid = false;
			if (firstErrorIndex === 3) {
				firstErrorIndex = 1;
			}
		} else {
			setErrorLocation(undefined);
		}
		if (!city) {
			language === "en" ? setErrorCity("Please enter a valid city.") : setErrorCity("Veuillez inscrire un nom de ville valide.")
			isAllValid = false;
			if (firstErrorIndex === -1) {
				firstErrorIndex = 5;
			}
		} else {
			setErrorCity(undefined);
		}
		if (!region) {
			language === "en" ? setErrorRegion("Please enter a valid province/territory.") : setErrorRegion("Veuillez inscrire un nom de province/territoire valide.")
			isAllValid = false;
			if (firstErrorIndex === -1) {
				firstErrorIndex = 6;
			}
		} else {
			setErrorRegion(undefined);
		}

		// Postal code regular expression
		const pattern = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
		if (!pattern.test(postalCode)) {
			language === "en" ? setErrorPostalCode("Please enter a valid postal code.") : setErrorPostalCode("Veuillez inscrire un code postal valide.")
			isAllValid = false;
			if (firstErrorIndex === -1) {
				firstErrorIndex = 7;
			}
		} else {
			setErrorPostalCode(undefined);
		}
		return [isAllValid, scrollArray[firstErrorIndex]];
	}, [schoolName,
		endDate,
		startDate,
		address,
		location,
		city,
		region,
		postalCode]);

	// GET request
	useEffect(() => {
		window.scrollTo(0, 0);
		axios.get(`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current/homepage/fair-info`, {
		  withCredentials: true
		}).then((res) => {
			if(res.status === 200) {
				setSavedData(res.data);
				setSchoolName(res.data.name);
				setDefaultStartDate(convertStringToDate(res.data.fairInfo.start));
				setDefaultEndDate(convertStringToDate(res.data.fairInfo.end));
				setStartDate(convertStringToDate(res.data.start));
				setEndDate(convertStringToDate(res.data.end));
				setLocation(res.data.location);
				setAddress(res.data.address.line1);
				setCity(res.data.address.city);
				setRegion(res.data.address.province);
				setPostalCode(res.data.address.postalCode);
				res.data.welcomeMessage.english === "" ? setEnglishWelcomeMessage("Get ready! The Book Fair is coming our way (YAY!). Choosing their own books empowers kids and inspires them on their journey to becoming lifelong readers. We can't wait to see you!") : setEnglishWelcomeMessage(res.data.welcomeMessage.english);
				res.data.welcomeMessage.french === "" ? setFrenchWelcomeMessage("Préparez-vous! Le Festival du Livre est en route (YOUPI!). Le fait de choisir leurs propres livres valorise les enfants et les incite à devenir des lecteur·rices pour la vie. Nous avons hâte de vous voir!") : setFrenchWelcomeMessage(res.data.welcomeMessage.french);
				res.data.thankYouMessage.english === "" ? setEnglishThankYouMessage("Thank you for making our school's Book Fair the Best. Fair. Ever! I'm always so excited to see your kids discover new books. All that Bookjoy wouldn't be possible without our school community.") : setEnglishThankYouMessage(res.data.thankYouMessage.english);
				res.data.thankYouMessage.french === "" ? setFrenchThankYouMessage("Merci d’avoir fait du Festival du Livre de notre école une réussite! C’est toujours un grand bonheur de voir vos enfants découvrir de nouveaux livres. Un tel événement ne serait pas possible sans la participation de notre communauté scolaire.") : setFrenchThankYouMessage(res.data.thankYouMessage.french);
				setCash(res.data.paymentOptions.cash);
				setChecks(res.data.paymentOptions.cheque);
				setCreditCard(res.data.paymentOptions.card);
				setProvinces(res.data.provinces);
				getRequestDone.current = true;
				updateUserLoginStatus(true);
				updateUserFairId(res.data.fairId);
				updateUserAccountId(res.data.bookfairAccountId)
				updatePageType("homepage-builder");
			} else {
				handleGenericSuccess(res);
			}
		})
		.catch((error) => {
		  handleGenericErrors(error);
		})
	}, [])

	// Validates input after every change (but not on page load)
	useEffect(() => {
		if (attemptedToSubmit) {
				dataValidate(language);
		}
		}, [language, attemptedToSubmit, dataValidate]);

	// Attempt to save updates to backend
	const saveFairInfo = async () => {
		const [validationBoolean, scrollElement] = dataValidate(language);
			try {
				if (validationBoolean) {
					await axios
						.put(
							`${process.env.REACT_APP_ENV_BASE_URL}/api/user/fairs/current/homepage/fair-info`,
							{
								name: schoolName,
								start: convertDateToString(startDate),
								end: convertDateToString(endDate),
								location: location,
								address: {
									line1: address,
									city: city,
									province: region,
									postalCode: postalCode,
								},
								thankYouMessage: {
									english: englishThankYouMessage === "" ? "Thank you for making our school's Book Fair the Best. Fair. Ever! I'm always so excited to see your kids discover new books. All that Bookjoy wouldn't be possible without our school community." : englishThankYouMessage,
									french: frenchThankYouMessage === "" ? "Merci d’avoir fait du Festival du Livre de notre école une réussite! C’est toujours un grand bonheur de voir vos enfants découvrir de nouveaux livres. Un tel événement ne serait pas possible sans la participation de notre communauté scolaire." : frenchThankYouMessage,
								},
								welcomeMessage: {
									english: englishWelcomeMessage === "" ? "Get ready! The Book Fair is coming our way (YAY!). Choosing their own books empowers kids and inspires them on their journey to becoming lifelong readers. We can't wait to see you!" : englishWelcomeMessage,
									french: frenchWelcomeMessage === "" ? "Préparez-vous! Le Festival du Livre est en route (YOUPI!). Le fait de choisir leurs propres livres valorise les enfants et les incite à devenir des lecteur·rices pour la vie. Nous avons hâte de vous voir!" : frenchWelcomeMessage,
								},
								paymentOptions: {
									cash: cash,
									cheque: checks,
									card: creditCard
								}
							},
							{ withCredentials: true }
						)
						.then((response) => {
							if (response.status === 200) {
								setAllowNavigation(true);
							} else {
								handleGenericSuccess(response);
							}
						})
						.catch((err) => {
							handleGenericErrors(err);
						});
						setAllChangesSaved(true);
						setBuilderNavDisabled(false);
						setAllowNavigation(true);
				} else {
					// reset blank optional fields to default text
					if (englishWelcomeMessage === "") {
						setEnglishWelcomeMessage(savedData.welcomeMessage.english);
					}
					if 	(frenchWelcomeMessage === "") {
						setFrenchWelcomeMessage(savedData.welcomeMessage.french);
					}
					if (englishThankYouMessage === "") {
						setEnglishThankYouMessage(savedData.thankYouMessage.english);
					}
					if (frenchThankYouMessage === "") {
						setFrenchThankYouMessage(savedData.thankYouMessage.french);
					}

					const element = document.getElementById(scrollElement);
					element && element.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
						inline: 'center'
					});
					setSaveChangesModalOpen(false);
					setBuilderNavDisabled(false);
					setAttemptedToSubmit(true);
				}
			} catch (err) {
				handleGenericErrors(err);
			}
	};

	// Allows for navigation to next page after clicking discard changes on save changes modal
	useEffect(() => {
		if (allChangesSaved && allowNavigation) {
			navigate(builderNextLocation);
		}
	}, [
		allChangesSaved,
		saveChangesModalOpen,
		allowNavigation,
		navigate,
		builderNextLocation,
	]);

	// Tracks if there are changes made
	useEffect(() => {
		if (getRequestDone.current) {
			setAllChangesSaved(false);
		}
	}, [
		schoolName,
		defaultStartDate,
		defaultEndDate,
		endDate,
		startDate,
		location,
		address,
		city,
		region,
		postalCode,
		englishWelcomeMessage,
		frenchWelcomeMessage,
		cash,
		checks,
		creditCard,
		englishThankYouMessage,
		frenchThankYouMessage
	]);

	// When save changes modal opens, scroll to top of page
	useEffect(() => {
		if (saveChangesModalOpen) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}
	}, [saveChangesModalOpen]);

	return (
				<>
					<StyledInformationPage>
						<PageIcon
							src={`${damAssetsOrigin}canadatoolkit/homepagebuilder/FairInformation.svg`}
							defaultImg={MessageIcon}
							alt="Information Form Icon"
						/>
						<StyledHeading>{language === "en" ? "Fair Information" : "Informations sur le Festival"}</StyledHeading>
						<StyledBodyTextSubheading>
						{language === "en" ?
							`Welcome visitors to your Fair with a personal
							message. Then write a message to appear on your
							homepage after your Fair to thank shoppers for
							supporting your school.` :
							`Accueillez les visiteur·ses à votre Festival 
							avec un message personnel. Rédigez ensuite un 
							message qui apparaîtra sur votre page d’accueil
							 après le Festival pour remercier les acheteur·ses
							  de soutenir votre école.`
						}
						</StyledBodyTextSubheading>
						<Wrapper>
							<FlexRow>
								<TextInput
									id={"school-name"}
									label={language === "en" ? "School Name" : "Nom de l’école"}
									value={schoolName?.toUpperCase()}
									onChange={setSchoolName}
									disabled={saveChangesModalOpen}
									error={errorSchoolName}
								/>
							</FlexRow>
							{language === "en" ?
							<FlexRow fairDatesText={true}>
								<StyledLeftText>
								Fair Dates
								<br></br>
								<StyledBoldText>Note:</StyledBoldText> Any customized information <StyledBoldText>does not</StyledBoldText> have an effect on the physical Fair.
								</StyledLeftText>
							</FlexRow> :
							<FlexRow fairDatesText={true}>
								<StyledLeftText>
								Dates du Festival
								<br></br>
								<StyledBoldText>Remarque :</StyledBoldText> Les informations personnalisées <StyledBoldText>n’ont pas</StyledBoldText> d’effet sur le Festival en personne.
								</StyledLeftText>
							</FlexRow>
							}
							<FlexRow>
								<InlineElement id={"start-date"} onFocus={() => openStartCalendar()}>
									<DatePickerComponent
										language={language}
										label={language === "en" ? "Start Date" : "Date de début"}
										value={startDate}
										onChange={setStartDate}
										defaultStart={subDays(defaultStartDate, 5)}
										defaultEnd={defaultEndDate}
										error={errorStartDate}
										isOpen={isStartCalendarOpen}
										onClick={() => setIsStartCalendarOpen(true)}
										disabled={saveChangesModalOpen}
										afterFair={false}
									/>
								</InlineElement>
								<InlineElement id={"end-date"} onFocus={() => openEndCalendar()}>
									<DatePickerComponent
										language={language}
										label={language === "en" ? "End Date" : "Date de fin"}
										value={endDate}
										onChange={setEndDate}
										defaultStart={defaultStartDate}
										defaultEnd={addDays(defaultEndDate, 5)}
										error={errorEndDate}
										isOpen={isEndCalendarOpen}
										onClick={() => setIsEndCalendarOpen(true)}
										disabled={saveChangesModalOpen}
										afterFair={false}
									/>
								</InlineElement>
							</FlexRow>
								<FlexRow 
									onFocus={() => resetCalendars()}
								>
									<TextInput
										id={"location"}
										label={language === "en" ? "Book Fair Location (optional: e.g. gym, library, etc.)" : "Emplacement du Festival du Livre (facultatif : p. ex. gymnase, bibliothèque, etc.)" }
										value={location}
										onChange={setLocation}
										disabled={saveChangesModalOpen}
										error={errorLocation}
									/>
								</FlexRow>
							<FlexRow>
								<InlineElement>
									<TextInput
										id={"address"}
										label={language === "en" ? "Address" : "Adresse"}
										value={address?.toUpperCase()}
										onChange={setAddress}
										disabled={saveChangesModalOpen}
										error={errorAddress}
									/>
								</InlineElement>
								<InlineElement>
									<TextInput
										id={"city"}
										label={language === "en" ? "City" : "Ville"}
										value={city?.toUpperCase()}
										onChange={setCity}
										disabled={saveChangesModalOpen}
										error={errorCity}
									/>
								</InlineElement>
							</FlexRow>
							<FlexRow>
								<InlineElement id={"region"}>
									<SelectInput
										label={language === "en" ? "Province/Territory" : "Province/Territoire"}
										optionChosen={region}
										onChange={setRegion}
										options={formatRegions(provinces)}
										defaultText="Select One"
										disabled={saveChangesModalOpen}
										tabIndex={saveChangesModalOpen ? -1 : 0}
										error={errorRegion}
									/>
								</InlineElement>
								<InlineElement id={"postal-code"}>
									<TextInput
										label={language === "en" ? "Postal Code" : "Code postal"}
										value={postalCode}
										onChange={setPostalCode}
										disabled={saveChangesModalOpen}
										error={errorPostalCode}
									/>
								</InlineElement>
							</FlexRow>
							{language === "en" ?
								<React.Fragment>
								{!afterFair && (
									<>
										<FlexRow id={"english-english-welcome"}>
											<LimitedTextArea
												label="Customize your English welcome message (optional)"
												value={englishWelcomeMessage}
												limit={400}
												onChange={setEnglishWelcomeMessage}
												disabled={saveChangesModalOpen}
												language={language}
											/>
										</FlexRow>
										<StyledExpandTextArea>
											<StyledImg
												alt="arrow"
												isThankyoumessage={true}
												src={isEnglishThankYouMessage ? RedArrowUp : RedArrowRight}
												onClick={(e) => {
													setIsEnglishThankYouMessage(
														!isEnglishThankYouMessage
													);
												}}
											/>
											<StyledButton
												disabled={saveChangesModalOpen}
												onClick={(e) => {
													setIsEnglishThankYouMessage(
														!isEnglishThankYouMessage
													);
												}}
											>
												Customize your English post-Fair thank-you
												message (optional)
											</StyledButton>
										</StyledExpandTextArea>
									</>
								)}
								{(isEnglishThankYouMessage || afterFair) &&
									<FlexRow afterFair={true} id={"english-english-thank-you"}>
										<LimitedTextArea
											label={"Customize your English post-Fair thank-you message"}
											value={englishThankYouMessage}
											onChange={setEnglishThankYouMessage}
											limit={400}
											disabled={saveChangesModalOpen}
											language={language}
										/>
									</FlexRow>
								}
								{!afterFair && (
									<>
										<FlexRow id={"english-french-welcome"}>
											<LimitedTextArea
												label="Customize Your French Welcome Message (optional)"
												value={frenchWelcomeMessage}
												limit={400}
												onChange={setFrenchWelcomeMessage}
												disabled={saveChangesModalOpen}
												language={language}
											/>
										</FlexRow>
										<StyledExpandTextArea>
											<StyledImg
												alt="arrow"
												isThankyoumessage={true}
												src={isFrenchThankYouMessage ? RedArrowUp : RedArrowRight}
												onClick={(e) => {
													setIsFrenchThankYouMessage(
														!isFrenchThankYouMessage
													);
												}}
											/>
											<StyledButton
												disabled={saveChangesModalOpen}
												onClick={(e) => {
													setIsFrenchThankYouMessage(
														!isFrenchThankYouMessage
													);
												}}
											>
												Customize your French post-Fair thank-you
												message (optional)
											</StyledButton>
										</StyledExpandTextArea>
									</>
								)}
								{(isFrenchThankYouMessage || afterFair) &&
									<FlexRow id={"english-french-thank-you"} afterFair={true}>
										<LimitedTextArea
											label={"Customize your French post-Fair thank-you message"}
											value={frenchThankYouMessage}
											onChange={setFrenchThankYouMessage}
											limit={400}
											disabled={saveChangesModalOpen}
											language={language}
										/>
									</FlexRow>
								}
								</React.Fragment> :
								<React.Fragment>
								{!afterFair && (
									<>
										<FlexRow id={"french-french-welcome"}>
											<LimitedTextArea
												label="Personnalisez votre message d’accueil en français (facultatif)"
												value={frenchWelcomeMessage}
												limit={400}
												onChange={setFrenchWelcomeMessage}
												disabled={saveChangesModalOpen}
												language={language}
											/>
										</FlexRow>
										<StyledExpandTextArea>
											<StyledImg
												alt="arrow"
												isThankyoumessage={true}
												src={isFrenchThankYouMessage ? RedArrowUp : RedArrowRight}
												onClick={(e) => {
													setIsFrenchThankYouMessage(
														!isFrenchThankYouMessage
													);
												}}
											/>
											<StyledButton
												disabled={saveChangesModalOpen}
												onClick={(e) => {
													setIsFrenchThankYouMessage(
														!isFrenchThankYouMessage
													);
												}}
											>
												Personnalisez votre message de remerciement post-Festival en français (facultatif)
											</StyledButton>
										</StyledExpandTextArea>
									</>
								)}
								{(isFrenchThankYouMessage || afterFair) &&
									<FlexRow id={"french-french-thank-you"} afterFair={true}>
										<LimitedTextArea
											label={"Message de remerciement en français"}
											value={frenchThankYouMessage}
											onChange={setFrenchThankYouMessage}
											limit={400}
											disabled={saveChangesModalOpen}
											language={language}
										/>
									</FlexRow>
								}
																{!afterFair && (
									<>
										<FlexRow id={"french-english-welcome"}>
											<LimitedTextArea
												label="Personnalisez votre message d’accueil en anglais (facultatif)"
												value={englishWelcomeMessage}
												limit={400}
												onChange={setEnglishWelcomeMessage}
												disabled={saveChangesModalOpen}
												language={language}
											/>
										</FlexRow>
										<StyledExpandTextArea>
											<StyledImg
												alt="arrow"
												isThankyoumessage={true}
												src={isEnglishThankYouMessage ? RedArrowUp : RedArrowRight}
												onClick={(e) => {
													setIsEnglishThankYouMessage(
														!isEnglishThankYouMessage
													);
												}}
											/>
											<StyledButton
												onClick={(e) => {
													setIsEnglishThankYouMessage(
														!isEnglishThankYouMessage
													);
												}}
											>
												Personnalisez votre message de remerciement post-Festival en anglais (facultatif)
											</StyledButton>
										</StyledExpandTextArea>
									</>
								)}
								{(isEnglishThankYouMessage || afterFair) &&
									<FlexRow id={"french-english-thank-you"} afterFair={true}>
										<LimitedTextArea
											label={"Message de remerciement en anglais"}
											value={englishThankYouMessage}
											onChange={setEnglishThankYouMessage}
											limit={400}
											disabled={saveChangesModalOpen}
											language={language}
										/>
									</FlexRow>
								}
								</React.Fragment>
							}
							<LeftAlignWrapper>
								<StyledBodyTextPayment>
								{language === "en" ? "In-School Payment Options" : "Options de paiement à l’école"}
								</StyledBodyTextPayment>
								<StyledBodyTextPaymentCaption>
								{language === "en" ?
									`These payment options will be displayed
									on your homepage. Uncheck any box to
									remove it as a form of payment.` :
									`Ces options de paiement seront affichées 
									sur votre page d’accueil. Décochez une case 
									pour supprimer une option en tant que mode de paiement.`
								}
								</StyledBodyTextPaymentCaption>
								<StyledCheckboxOther
									label={language === "en" ? "Cash" : "Argent comptant"}
									checked={cash}
									tabIndex={0}
									disabled={saveChangesModalOpen}
									onChange={setCash}
								/>
								<StyledCheckboxOther
									label={language === "en" ? "Cheques (Payable to the school)" : "Chèques (à l’ordre de l’école)"}
									checked={checks}
									tabIndex={0}
									disabled={saveChangesModalOpen}
									onChange={setChecks}
								/>
								<StyledCheckboxOther
									label={language === "en" ? "Credit Card/Debit/Mydoh" : "Carte de crédit/débit/Mydoh"}
									checked={creditCard}
									tabIndex={0}
									disabled={saveChangesModalOpen}
									onChange={setCreditCard}
								/>
							</LeftAlignWrapper>
						</Wrapper>
						<StyledRedButton
							handleClick={() => {
								trackSaveAndPublishButton();
								trackInSchoolPaymentOptions(cash, checks, creditCard);
								setBuilderNextLocation(informationNextLocation);
								saveFairInfo();
								(savedData.welcomeMessage.english !== englishWelcomeMessage) && trackCustomizeYourMessageEnglish();
								(savedData.welcomeMessage.french !== frenchWelcomeMessage) && trackCustomizeYourMessageFrench();
								(savedData.thankYouMessage.english !== englishThankYouMessage) && trackCustomizeYourMessageThankYouEnglish();
								(savedData.thankYouMessage.french !== frenchThankYouMessage) && trackCustomizeYourMessageThankYouFrench();
							}}
							disabled={saveChangesModalOpen}
						>
							{language === "en" ? "Save & Publish" : "Enregistrer et publier"}
						</StyledRedButton>
						<StyledTextLink
							handleClick={() => {
								trackSkipButton();
								if (!allChangesSaved) {
									setBuilderNextLocation(informationNextLocation);
									setBuilderNavDisabled(!builderNavDisabled);
									setSaveChangesModalOpen(!saveChangesModalOpen);
								} else {
									navigate(informationNextLocation);
								}
							}}
							disabled={saveChangesModalOpen}
						>
							{language === "en" ? "Skip" : "Ignorer"}
						</StyledTextLink>
					</StyledInformationPage>

					<Prompt
						message={() => {
							setSaveChangesModalOpen(true);
							setBuilderNavDisabled(true);
						}}
						when={!allChangesSaved}
					/>
					<SaveChangesModal
						saveAndPublish={saveFairInfo}
						discardChanges={() => {
							setStartDate(savedData.homepageStartDate);
							setEndDate(savedData.homepageEndDate);
							setSchoolName(savedData.name);
							setLocation(savedData.location);
							setAddress(savedData.address.line1);
							setCity(savedData.address.city);
							setRegion(savedData.address.region);
							setPostalCode(savedData.address.postalCode);
							setEnglishWelcomeMessage(savedData.welcomeMessage.english);
							setFrenchWelcomeMessage(savedData.welcomeMessage.english);
							setEnglishThankYouMessage(savedData.thankYouMessage.english);
							setFrenchThankYouMessage(savedData.thankYouMessage.french);
							setCash(savedData.paymentOptions.cash.selected);
							setChecks(savedData.paymentOptions.cheque.selected);
							setCreditCard(savedData.paymentOptions.card.selected);
							trackDiscardChanges();
							setAllChangesSaved(true);
							setBuilderNavDisabled(false);
							setAllowNavigation(true);
						}}
						modalIsOpen={saveChangesModalOpen}
						handleModal={() => {
							setSaveChangesModalOpen(!saveChangesModalOpen);
						}}
						language={language}
					/>
				</>
	);
};

/*
	Page Specific Styles
*/
const StyledHeading = styled(Heading)`
	margin: 0;
`;
const StyledBodyTextSubheading = styled(BodyText)`
	margin: 0;
	max-width: 346px;
`;
const StyledLeftText = styled(BodyText)`
	margin: 0;
	text-align: left;
`;
const StyledBoldText = styled.span`
	font-weight: 700;
`;
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 600px;
	gap: 24px;

	@media (max-width: 719px) {
		gap: 16px;
	}
`;
const StyledRedButton = styled(RedButton)`
	margin-top: 4px;
`;
const StyledTextLink = styled(TextLink)`
	margin-top: -8px;
`;
const StyledInformationPage = styled(Page)`
	padding: 111px 0px 48px;
	gap: 24px; 

	@media (max-width: 719px) {
		padding: 111px 20px 48px;
	}
	@media (max-width: 319px) {
		padding: 95px 20px 32px;
	}
`;
const FlexRow = styled.div<{ afterFair?: boolean, fairDatesText?: boolean }>`
	margin-top: ${(props) => (props.afterFair ? "-8px" : "unset")};
	margin-bottom: ${(props) => (props.fairDatesText ? "-8px" : "")};
	display: inline-flex;
	width: 100%;
	column-gap: 40px;
	row-gap: 16px;
	flex-wrap: wrap;
`;
const InlineElement = styled.div`
	flex: 1 auto;
	width: calc(50% - 20px);
	min-width: 280px;
`;

const LeftAlignWrapper = styled.div`
	padding-top: 28px;
	border-top: 1px solid #e3e3e3;
	width: 100%;
	flex-direction: column;
	margin-top: 9px;

	@media (max-width: 719px) {
		margin-top: 17px;
	}
`;
const StyledCheckboxOther = styled(Checkbox)`
	margin-bottom: 20px;
	@media (max-width: 450px) {
		margin-bottom: 22px;
	}
`;

const StyledExpandTextArea = styled.div`
	margin: 0;
	background: none;
	border: none;
	color: #e81111;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	font-weight: 500;
	line-height: 15px;
	letter-spacing: 0px;
	text-align: left;
	align-content: center;
	display: inline-flex;
	width: 100%;
	height: 14px;
	align-items: center;
`;

const StyledButton = styled.button`
	background: none;
	border: none;
	color: #e81111;
	font-weight: 600;
	font-family: museo-sans;

	&:hover {
		cursor: pointer;
		color: #b90000;
		border-bottom: 1px solid #b90000;
		margin-bottom: -1px;
	}
	&:focus-visible {
		color: #b90000;
		border: 2px solid #b90000;
		box-sizing: border-box;
		border-radius: 4px;
		outline: none;
		margin-bottom: 0px;
    	padding-left: 4px;
	}
	&:active {
		cursor: pointer;
		color: #660000;
		border-bottom: 1px solid #660000;
		margin-bottom: -1px;
	}
`;
const StyledBodyTextPaymentCaption = styled(BodyText)`
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0px;
	text-align: left;
	margin: 11px 0px 19.5px;
`;

const StyledBodyTextPayment = styled(BodyText)`
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: 15px;
	letter-spacing: 0px;
	text-align: left;
	margin: 0;
`;

interface ImgProps {
	isThankyoumessage?: boolean;
}

const StyledImg = styled.img<ImgProps>`
	transform: ${(props) =>
		props.isThankyoumessage ? "none" : "rotate(-90deg)"};
`;

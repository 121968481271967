import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeScripts, initDumbleData } from "./DumbleData";
import { datadogRum } from '@datadog/browser-rum';

initializeScripts();
initDumbleData();
if(process.env.REACT_APP_DATADOG_ID !== undefined && process.env.REACT_APP_DATADOG_TOKEN !== undefined) {
	datadogRum.init({
		applicationId: process.env.REACT_APP_DATADOG_ID,
		clientToken: process.env.REACT_APP_DATADOG_TOKEN,
		// `site` refers to the Datadog site parameter of your organization
		// see https://docs.datadoghq.com/getting_started/site/
		site: 'datadoghq.com',
		service: 'bookfairs-ca-'+process.env.REACT_APP_ENV,
		env: 'prod',
		// Specify a version number to identify the deployed version of your application in Datadog
		// version: '1.0.0',
		sessionSampleRate: 100,
		sessionReplaySampleRate: 0,
		trackUserInteractions: false,
		trackResources: false,
		trackLongTasks: false,
		defaultPrivacyLevel: 'mask',
	});
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

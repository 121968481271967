import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BookFairHomepageCard from "../BookFairHomepageCard";
import MyContactsCard from "../MyContactsCard";
import { convertYMDStringToDate } from "../../../utils/DateConversion";
import ShareTheFairFundsCard from "../ShareTheFairFundsCard";
import FlyersCard from "../FlyersCard";
import SalesHistoryCard from "../SalesHistoryCard";
import FairDatesCard from "../FairDatesCard";
import WrapUpCard from "../WrapUpCard";
import { PrintableComponent } from "../../PrintableComponent";
import { DateChangeSubmitPage } from "../../ConfirmYourFair/DateChange/DateChangeSubmit";

interface Props {
	fairInfo: any;
	organization: any;
	scholasticDollars: any;
	chairperson: any;
	consultant: any;
	homepage: any;
	coChairs: any | undefined;
	language: string;
	salesHistory: any;
}

const FairDetailsPageContent = ({
	fairInfo,
	organization,
	scholasticDollars,
	chairperson,
	consultant,
	homepage,
	coChairs,
	language,
	salesHistory
}: Props) => {	
	const [dateChangeSubmitPageOpen, setDateChangeSubmitPageOpen] =
		useState<boolean>(false);

	useEffect(() => {
		if (fairInfo.startDate) {
			const fairStart = convertYMDStringToDate(fairInfo.start);
			const oneMonthBeforeStart = new Date(fairStart);
			oneMonthBeforeStart.setMonth(fairStart.getMonth() - 1);
		}
	}, [fairInfo.start, fairInfo.startDate]);

	return (
		<Grid>
			<FullWidth>
				{(dateChangeSubmitPageOpen || fairInfo.requestDateChangePending) && (
					<DateChangeSubmitPage
						language={language}
					/>
				)}
				<Heading>
					<HeadingTitle>{language === "en" ? "Fair Details and Settings" : "Détails et paramètres du Festival"}</HeadingTitle>
					<HeadingFairInfo>
						<HeadingFairInfoText>
							{language === "en" ? "Account #:" : "Numéro de compte :"} {organization.bookfairAccountId}
						</HeadingFairInfoText>
						<HeadingFairInfoText>
							{language === "en" ? "Fair ID:" : "Numéro du Festival :"} {fairInfo.id}
						</HeadingFairInfoText>
						<HeadingFairInfoText className="print">
							<StyledPrintableComponent
								buttonText={language === "en" ? "Print Book Fair Acknowledgement" : "Imprimer l’Accord du Festival du Livre"}
								redTextButton={true}
								fairInfoData={fairInfo}								
								chairpersonData={chairperson}
								consultantData={consultant}
								organizationData={organization}
								className="print"
								language={language}
							/>
						</HeadingFairInfoText>
					</HeadingFairInfo>
				</Heading>
			</FullWidth>
			<LeftColumn>
				<FairDatesCard
					startDate={fairInfo.start}
					endDate={fairInfo.end}
					setupDate={fairInfo.setup}
					setDateChangeSubmit={setDateChangeSubmitPageOpen}
					language={language}
				/>
		
				<BookFairHomepageCard
					homepageUrl={homepage.url}
					language={language}
				/>

				{scholasticDollars.balance != null && (
					<ShareTheFairFundsCard balance={scholasticDollars.balance} balanceDate={scholasticDollars.balanceDate} language={language} />
				)}

				<WrapUpCard language={language} />

			</LeftColumn>
			<RightColumn>

				<FlyersCard
					fliers={fairInfo.fliers} language={language} id={fairInfo.id} planningKitShipped={fairInfo.planningKitShipped}  />

				<SalesHistoryCard salesHistory={salesHistory} language={language} />
				
				<MyContactsCard
					firstName={consultant.firstName}
					lastName={consultant.lastName}
					email={consultant.email}
					language={language}
			/>
			</RightColumn>
		</Grid>
	);
};
export const Heading = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	margin: 48px 0;
	@media (max-width: 720px) {
		flex-direction: column;
		justify-content: unset;
		gap: 16px;
	}
`;

export const HeadingTitle = styled.p`
	font-weight: 500;
	font-size: 39px;
	line-height: 42px;
	color: #333333;
	@media (max-width: 720px) {
		font-size: 36px;
		line-height: 39px;
	}
`;
const HeadingFairInfo = styled.div`
	display: flex;
	flex-direction: column;
	@media (max-width: 720px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const HeadingFairInfoText = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	text-align: right;
	color: #333333;
	&.print {
		color: #e81111;
	}
`;

const FullWidth = styled.div`
	grid-column-start: 1;
	grid-column-end: 24;
`;
const LeftColumn = styled.div`
	grid-column-start: 1;
	grid-column-end: 16;
	@media (max-width: 720px) {
		grid-column-start: 1;
		grid-column-end: 24;
	}
`;
const RightColumn = styled.div`
	grid-column-start: 17;
	grid-column-end: 24;
	@media (max-width: 720px) {
		grid-column-start: 1;
		grid-column-end: 24;
	}
`;
const StyledPrintableComponent = styled(PrintableComponent)`
	width: unset;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	text-align: right;
`;

export const Grid = styled.div`
	display: grid;
	margin: 0 auto 24px;
	grid-template-columns: repeat(23, 1fr);
	width: -webkit-fill-available;
	width: -moz-available;
	max-width: 1240px;

	/* IE friendly.....*/
	display: -ms-grid;
	-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr
		1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

	@media (max-width: 1599px) {
		margin: 0px 180px 24px;
	}

	@media (max-width: 1279px) {
		margin: 0px 140px 24px;
	}

	@media (max-width: 959px) {
		margin: 0px 40px 24px;
	}

	@media (max-width: 519px) {
		margin: 0px 20px 24px;
	}
`;
export default FairDetailsPageContent;

// Disables scrolling on the body
const disableScrolling = () => {
	document.body.style.overflow = "hidden";
};

// Enables scrolling on the body
const enableScrolling = () => {
	document.body.style.overflow = "visible";
};

export { disableScrolling, enableScrolling };
